<template>
  <div class="progress-content">
    <img src="~@/assets/icons/follow-products-info.svg" width="40" height="40" />
    <div class="progress-text">En Çok Satılan 3 Ürünümüzü Yok Satma Oranı</div>
    <div class="top-empty-info">Henüz bir işlemin bulunmamaktadır.</div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.progress-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.progress-text {
  font-size: 16px;
  font-weight: 700;
  margin-right: 5px;
  color: #0e4cfb;
  margin-top: 20px;
}

.top-empty-info {
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
}
</style>
